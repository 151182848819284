<template>
    <div>
        <img :src="images?.[currentSlide]?.url" class="w-full h-auto aspect-auto rounded-lg" />
        <carousel v-if="images.length > 0" :items-to-show="5" :itemsToScroll="1" ref="carouselRef" class="mt-3 mb-2">
            <slide v-for="slide, idx in images" :key="slide.id">
                <div class="h-full flex items-end mx-1">
                    <img class="w-full h-auto cursor-pointer rounded-lg aspect-video object-cover" :src="slide.url"
                        @click="carouselRef.slideTo(idx); currentSlide = idx" />
                </div>
            </slide>
        </carousel>
    </div>
</template>

<script setup>
import { defineProps, toRefs, ref } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

const props = defineProps({ images: Array })
const { images } = toRefs(props)

const carouselRef = ref(null)
const currentSlide = ref(0)
</script>
