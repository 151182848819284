<template>
    <ThingProfile :thing="store.getters.getThing" />
</template>

<script setup>
import { useStore } from 'vuex';
import ThingProfile from '../components/things/viewThing/ThingProfile.vue';

const store = useStore()
</script>
