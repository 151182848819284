<template>
<div>
    <!-- Top bar -->
    <div
        class="top-20 z-20 flex flex-row border-t-2 justify-between lg:px-9 px-7 py-3 bg-primary dark:bg-primaryDark rounded-b-md">
        <div class="flex flex-row gap-2 max-w-max flex-wrap self-center text-xs text-gray-500 dark:text-white font-medium">
            <router-link :to="{ name: 'Home' }">
                <font-awesome-icon icon="fa-solid fa-house" class="self-center hover:text-gray-700 dark-hover:text-gray-400" size="xs" />
            </router-link>
            <template v-for="item in path" :key="item.id">
                <font-awesome-icon icon="fa-solid fa-chevron-right" class="self-center" size="xs" />
                <router-link :to="item.to">
                    <p class="hover:text-gray-700 dark-hover:text-gray-400">
                        {{ item.label }}
                    </p>
                </router-link>
            </template>
        </div>
    </div>
    <div class="2xl:max-w-7xl xl:max-w-5xl lg:max-w-4xl max-w-3xl m-auto grid md:grid-cols-4 grid-cols-2 md:gap-4 gap-y-4 mt-3 dark:text-gray-200 pb-80 px-4"
        v-if="Object.keys(props.thing).length !== 0">

        <div class="col-span-3">
            <ThingImageCard :thing="props.thing" />
        </div>

        <div class="md:col-span-1 col-span-2 grid md:grid-flow-row md:grid-cols-1 md:auto-rows-min grid-cols-2 gap-4">
            <div class="">
                <div class="bg-primary rounded-lg dark:bg-primaryDark p-3">
                    <p class="font-semibold text-gray-500 dark:text-gray-400">Properties</p>
                    <ThingCustomProp v-for="prop in props.thing.properties" :key="prop.id" :prop="prop" />
                </div>
            </div>
            <!-- Placeholders: -->
            <!-- <div class="">
                <div class="bg-primary rounded-lg dark:bg-primaryDark p-3">
                    <p class="font-semibold text-gray-500 dark:text-gray-400">Properties</p>
                    <ThingCustomProp v-for="prop in props.thing.properties" :key="prop.id" :prop="prop" />
                </div>
            </div>
            <div class="">
                <div class="bg-primary rounded-lg dark:bg-primaryDark p-3">
                    <p class="font-semibold text-gray-500 dark:text-gray-400">Properties</p>
                    <ThingCustomProp v-for="prop in props.thing.properties" :key="prop.id" :prop="prop" />
                </div>
            </div>
            <div class="">
                <div class="bg-primary rounded-lg dark:bg-primaryDark p-3">
                    <p class="font-semibold text-gray-500 dark:text-gray-400">Properties</p>
                    <ThingCustomProp v-for="prop in props.thing.properties" :key="prop.id" :prop="prop" />
                </div>
            </div> -->
        </div>

        <div class="col-span-3" v-if="props.thing?.description">
            <div class="bg-primary dark:bg-primaryDark p-4 rounded-lg">
                <p class="font-semibold text-gray-500 dark:text-gray-400 mb-1">Description</p>
                <p>{{ props.thing?.description }}</p>
            </div>
        </div>

    </div>
    <!-- Error card -->
    <!-- <div else class="max-w-xl m-auto grid md:grid-cols-3 grid-cols-2 gap-4 mt-20 dark:text-gray-200 px-4">
        <div class="col-span-3 bg-primary dark:bg-primaryDark p-4 rounded-lg">
            <div class="grid grid-flow-auto place-items-center gap-2">
                <font-awesome-icon icon="fa-solid fa-circle-exclamation" size="2x" class="" />
                <p class="text-3xl">Thing could not be found</p>
                <div class="row-start-2 col-start-2">
                    <router-link :to="{ name: 'Things' }" class="">
                        <button class="bg-gray-100 hover:shadow rounded-lg p-2 m-2 flex flex-row">
                            <font-awesome-icon icon="fa-solid fa-chevron-left" size="1x"
                                class="place-self-center px-2" />
                            <p class="text-xl text-center">Go back</p>
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div> -->
</div>
</template>

<script setup>
import { computed, defineProps } from "vue"
// import CapitalizeWords from "@/utils/CapitalizeWords"
import capitalize from "../../../utils/Capitalize"
import ThingCustomProp from "../../widgets/ThingCustomProp.vue"
import ThingImageCard from "./ThingImageCard.vue"
const props = defineProps({ thing: Object })

// const name = computed(() => CapitalizeWords(props.thing?.name))
const path = computed(() => props
    .thing?.categories?.[0]?.branch?.slice().reverse()
    .map((item, i, arr) => ({
        ...item,
        label: capitalize(item.label),
        to: { name: "Things", params: { thingPath: arr.slice(0, i + 1).map(str => str.label.toLowerCase()) } }
    }))
    ?? [])
// const images = computed(() => props
//     .thing?.medialibrary?.images
//     .map(img => ({ id: img.id, url: img.image })) ?? [])
// const location = computed(() => {
//     const place = props.thing?.storage?.space?.place
//     if (place) return `${place.city}, ${place.country}`
//     else return null
// })

// const log = console.log
// const averageScore = 3.7
// const reviewCount = 1345
// const images = [
//     { id: 5, url: "https://pixabay.com/get/g24cb5d7cfefc2971585ca7670a51524d23fd04f29c90767b56323ca02f28e99b57077934799c1ca3c69cee674d8f18dfe9003728d5976f636a6450b07535eeca_640.jpg" },
//     { id: 1, url: "https://pixabay.com/get/gce797ec6f23535638357b0ab874c48b939e29534fec2c99e162685746a3fa662e37b84fcf918d9a6bf934f36b2a86a710ff0295f1c35ccc45e97eb44c8a7e417_640.jpg" },
//     { id: 3, url: "https://pixabay.com/get/g2a842a0031c4bc77a7f7e75d474a2d8c99afeb5cd80fab58f31c28d4cd6d82009edf2b40c2168b473bf36aa5418c16b0f0176582deeb553056a376ace5897002_640.jpg" },
//     { id: 2, url: "https://pixabay.com/get/g144bba82774b6e2a321a6cfa7582164209bd1dd8e336f94f7f12344673245ca1f68b8f32d32624c2b16c127a932ebd9b_640.jpg" },
//     { id: 8, url: "https://pixabay.com/get/g370cdcd07afc8a966ea80d1e897090fd1d2f3e228a417c2b73be2c72f90b51fbe3b115385de9c60b22c04d5270ee412cb4c14ba6b683adb8ee3401d805095e5f_640.jpg" },
//     { id: 4, url: "https://pixabay.com/get/gc21fa6dfaa68520073a0f00e6c56a878bc0e94d36955c06aec6b0fc0cbaad6b22296abd3dce43288746e4d1d6a9017e0023a04f0e3627413307774cf3d88fbf4_640.jpg" },
//     { id: 6, url: "https://pixabay.com/get/g7d01476c36b07cb9106d458aaa96330e2887ac62cc0fb2e32067143bbf6c1743d79c10f658c49cb63059839074beca5d78f4c7171c6a3b04e53f3ebc20c45d7b_640.jpg" },
//     { id: 7, url: "https://pixabay.com/get/g22b129a8b9f12214588df2ba8487193a7f41cedc4693ea3d6f091fcf8372541a840c1b3e805b678318380c25b7e17d74eb8cde2878e5b0af5f29fb6c7ecdd217_640.jpg" },
//     { id: 9, url: "https://pixabay.com/get/gfefd86a25a3cb850395170e383d7ab2801355b2631369fae80bbe8aa04cc38cabb12e30cc19fd6ec7652e4f86e5f92deabec52f5ee0207fcdfd7241d1feffded_640.jpg" },
// ]
</script>
