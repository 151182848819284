<template>
    <CategoryTable :items="items" :path="path" :tableColumns="tableColumns" :filters="filters" :numberOfResults="41"
        @filterUpdate="filterUpdate" @resultRangeUpdate="resultRangeUpdate" @searchUpdate="searchUpdate" />
</template>

<script setup>
import CategoryTable from './CategoryTable.vue';

const filterUpdate = (selectedFilters, filtersOnInclude) => {
    console.log("filterUpdate", selectedFilters, filtersOnInclude)
}

const resultRangeUpdate = (from, to) => {
    console.log(`New range needs to be loaded into items. From ${from} to ${to}.`)
}

const searchUpdate = newSearchString => {
    console.log(`New search string. ${newSearchString}`)
}

const items = [
    { title: "Person 1 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/57e8dd474954aa14f1dc8460962e33791c3ad6e04e5074417c2e7dd19249c3_640.jpg" },
    { title: "Person 2 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/57e0d2444953ad14f1dc8460962e33791c3ad6e04e5074417c297edd9f44c2_640.jpg" },
    { title: "Person 3 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/55e1d3474b5bb10ff3d8992cc12c30771037dbf85254794075277bd09245_640.jpg" },
    { title: "Person 4 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/55e3d24a4f55a414f1dc8460962e33791c3ad6e04e50744172287ad29749c3_640.jpg" },
    { title: "Person 5 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/54e3d0424a57ad14f1dc8460962e33791c3ad6e04e5074417d2e72dc964ec2_640.jpg" },
    { title: "Person 6 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/57e0d5414c51b10ff3d8992cc12c30771037dbf852547941742a79d6914f_640.jpg" },
    { title: "Person 7 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/57e8dd474954aa14f1dc8460962e33791c3ad6e04e5074417c2e7dd19249c3_640.jpg" },
    { title: "Person 8 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/57e0d2444953ad14f1dc8460962e33791c3ad6e04e5074417c297edd9f44c2_640.jpg" },
    { title: "Person 9 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/55e1d3474b5bb10ff3d8992cc12c30771037dbf85254794075277bd09245_640.jpg" },
    { title: "Person 10 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/55e3d24a4f55a414f1dc8460962e33791c3ad6e04e50744172287ad29749c3_640.jpg" },
    { title: "Person 11 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/54e3d0424a57ad14f1dc8460962e33791c3ad6e04e5074417d2e72dc964ec2_640.jpg" },
    { title: "Person 12 Name", subtitle: "Subtitle", image: "https://randompicturegenerator.com/img/picture-generator/57e0d5414c51b10ff3d8992cc12c30771037dbf852547941742a79d6914f_640.jpg" },
]

const path = [
    { label: "Things", url: "/things" },
    { label: "Props", url: "/things/props" },
    { label: "Furniture", url: "/things/props/furniture" },
    { label: "Seating", url: "/things/props/furniture/seating" },
    { label: "Sofas", url: "/things/props/furniture/seating/sofas" },
    { label: "Empty" },
]

const tableColumns = [
    { label: "Name", key: "title" },
    { label: "Job", key: "job" },
    { label: "Location", key: "location" },
]

const filters = [
    {
        label: "Beard Type",
        key: "beardType",
        includeExclude: true,
        category: "General",
        type: "Dropdown",
        options: [
            { label: "Full Beard", key: "fullBeard", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Goatee", key: "goatee", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Moustace", key: "moustace", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Neckbeard", key: "neckbeard", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "None", key: "none", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
        ]
    },
    {
        label: "Beard Colour",
        key: "beardColour",
        includeExclude: true,
        category: "General",
        type: "Dropdown",
        options: [
            { label: "Black", key: "black", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Blonde", key: "blonde", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Brown", key: "brown", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Dark", key: "dark", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Red", key: "red", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
        ]
    },
    {
        label: "Beard Colour",
        key: "beardColour2",
        includeExclude: true,
        category: "Other",
        type: "Dropdown",
        options: [
            { label: "Black", key: "black", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Blonde", key: "blonde", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Brown", key: "brown", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Dark", key: "dark", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
            { label: "Red", key: "red", image: "https://pixabay.com/get/gd392250c8bba60e883351c0f0f71218e47174bca13e26c674b68d357642e169461e6b5ce6f497119c343199f4e0976a94e25dbf1616f71e392b123043cf6e70e_640.jpg" },
        ]
    }
]
</script>
