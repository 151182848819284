<template>
    <div class="bg-primary dark:bg-primaryDark p-4 rounded-lg">
        <div class="pb-2">
            <p class="text-2xl font-semibold dark:text-gray-200">{{ name }}</p>
            <div class="flex flex-row justify-between text-gray-600 dark:text-gray-400 text-sm xs:text-base items-center">
                <div class="flex items-center">
                    <div class="flex flex-row items-center">
                        <template v-for="i in Math.round(averageScore)" :key="i">
                        <font-awesome-icon icon="fa-solid fa-circle" class="ml-0.5 sm:ml-0 sm:w-5 w-3"  />
                    </template>
                    <template v-for="i in 5 - Math.round(averageScore)" :key="i">
                        <font-awesome-icon icon="fa-regular fa-circle" class="ml-0.5 sm:ml-0 sm:w-5 w-3"  />
                    </template>
                    </div>
                    <p class="pl-1">{{ `${reviewCount} reviews` }}</p>
                </div>
                <p class="line-clamp-2">{{ props.thing?.owner?.basic.fullName }}</p>
                <div v-if="location" class="flex flex-row gap-1">
                    <p>
                        {{ location }}
                    </p>
                </div>
                <router-link :to="{ name: 'EditThing', params: { id: thing.id } }">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                </router-link>
            </div>
        </div>
        <div v-if="images.length > 0 || props.thing?.description" class="">
            <div class="">
                <div>
                    <MultiImageCarousel :images="images" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from "vue"
import CapitalizeWords from "@/utils/CapitalizeWords"
import MultiImageCarousel from "../../widgets/MultiImageCarousel.vue"

const props = defineProps({ thing: Object })

const name = computed(() => CapitalizeWords(props.thing?.name))
const images = computed(() => props
    .thing?.medialibrary?.images
    .map(img => ({ id: img.id, url: img.image })) ?? [])
const location = computed(() => {
    const place = props.thing?.storage?.space?.place
    if (place) return `${place.city}, ${place.country}`
    else return null
})
const averageScore = 3.7
const reviewCount = 1345
</script>
