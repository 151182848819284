<template>
    <div v-if="$props.display" class="flex w-full "
        :class="[justifyOrientation === 'end' ? 'justify-end' : 'justify-around']">
        <LoadingSpinner v-if="this.$store.getters.isLoading" size="small" />
        <div v-else class="flex flex-row">
            <SaveButton :showRightIcon="showRightIcon" :buttonClasses="saveButtonClasses" :title="title"
                @onSave="$emit('onOk')" />
        </div>
    </div>
</template>

<script>
import SaveButton from "@/components/inputs/SaveButton";
import LoadingSpinner from "@/components/widgets/LoadingSpinner";
export default {
    name: "SaveCancelForm",
    components: { SaveButton, LoadingSpinner },
    props: {
        display: Boolean,
        justifyOrientation: String,
        version: {
            required: false,
            default: "1",
        },
        title: {
            required: false,
            default: "Ok",
        },
        buttonClasses: {
            required: false,
            default:
                "inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        },
        transparentButtons: {
            required: false,
            default: false,
        },
        showRightIcon: {
            required: false,
            default: false,
        },
        showLeftIcon: {
            required: false,
            default: false,
        },
    },
    computed: {
        transparentButtonClasses() {
            return 'cursor-pointer my-2 my-2 px-1.5 sm:px-4 py-2 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark'
        },
        cancelButtonClasses() {
            if (this.transparentButtons) return this.transparentButtonClasses;
            return "inline-flex items-center px-6 py-2 border border-gray-200 text-base font-medium rounded-md shadow-sm text-gray-700 bg-gray-100 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
        },
        saveButtonClasses() {
            if (this.transparentButtons) return this.transparentButtonClasses;
            return this.buttonClasses;
        }
    },
};
</script>
