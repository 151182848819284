<template>
    <div v-if="prop.value != null" @click="onClick"
        :class="`text-sm ${prop.propertyType === 'TABLE' ? 'cursor-pointer' : ''}`">
        <p class="inline-block w-max mr-2"><b>{{ CapitalizeWords(prop.name) }}:</b></p>

        <p class="inline-block w-max max-w-full" v-if="BASIC_DISPLAY_TYPES.includes(prop.propertyType)">
            {{ CapitalizeWords(prop.value) }}
        </p>
        <p class="inline-block w-max max-w-full" v-else-if="prop.propertyType === 'OPTION_M'">
            {{ joinInEnglish(prop.value.split(";").map(trim).map(CapitalizeWords)) }}
        </p>
        <p class="inline-block w-max max-w-full" v-else-if="prop.propertyType === 'TABLE'">
            {{ prop.table[0][0] }}: {{ prop.table[prop.value][0] }}
        </p>
        <p class="inline-block w-max max-w-full" v-else-if="prop.propertyType === 'BOOLEAN'">
            {{ prop.value ? "Yes" : "No" }}
        </p>
        <p class="inline-block w-max max-w-full" v-else>ERROR: Unknown property type "{{ prop.propertyType }}"</p>

        <Modal :modalActive="viewingTable">
            <div class="bg-white dark:bg-primaryDark dark:text-white p-5">
                <table class="w-full mb-5">
                    <thead>
                        <th v-for="system, idx in prop.table[0]" :key="idx">{{ system }}</th>
                    </thead>
                    <tbody>
                        <td v-for="item, idx in prop.table[Number(prop.value)]" :key="idx">{{ item }}</td>
                    </tbody>
                </table>
                <OkForm @onOk="viewingTable = false" class="col-span-2" justifyOrientation="end" :display="true" />
            </div>
        </Modal>
    </div>
</template>

<script setup>
import { defineProps, ref } from "vue"
import CapitalizeWords from "@/utils/CapitalizeWords"
import Modal from "./Modal.vue"
import OkForm from "../forms/OkForm.vue"

// Pure functions

const trim = str => str.trim()

const joinInEnglish = strList => {
    if (strList.length === 0) return ""
    if (strList.length === 1) return strList[0]

    const allButLast = strList.slice(0, -1)
    const lastItem = strList[strList.length - 1]
    return allButLast.join(", ") + " and " + lastItem
}

const props = defineProps({
    prop: Object
})

const viewingTable = ref(false)

const onClick = () => {
    if (props.prop.propertyType === "TABLE")
        viewingTable.value = !viewingTable.value
}

const BASIC_DISPLAY_TYPES = ["STRING", "INT", "OPTION_S"]
</script>
